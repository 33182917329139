.button {
    padding: 0.5rem 4rem;
    position: relative;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

}

.button:hover>p {
    transform: scale(1.05);
}

.button:hover>.button__background {
    width: 100%;
}

.button>p,
.button>.button__background {
    transition: all 0.2s ease-out;
}

.button__background {
    background-color: var(--colorPrimary);
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.button>p {
    font-weight: bold;
    font-size: var(--fontSize-medium);
    text-decoration: none;
    color: white;
}