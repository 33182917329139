.intro-section {
  margin: calc(var(--defaultMargin) * 2) 0;
  text-align: center;
  line-height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
}

.highlight-text {
  font-size: var(--fontSize-XL);
}

.additional-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 3.5s ease, opacity 0.5s ease;
  text-align: center;
  line-height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3rem;
}

.expanded {
  max-height: 100vh;
  opacity: 1;
}

.collapsed {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 0.5s ease; 
}

/* Medium screens (e.g., tablets) */
@media (max-width: 768px) {
  .intro-section {
    margin: calc(var(--pageMargin-smaller));
    margin-left: var(--pageMargin-smaller);
    margin-right: var(--pageMargin-smaller);
  }

  .highlight-text {
    font-size: calc(var(--fontSize-XL) - 2px); /* Slightly reduce font size for better fit */
  }
}

/* Small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .intro-section {
     margin: calc(var(--pagemargin-smaller) * 2) 0;
  }

  .highlight-text {
    font-size: calc(var(--fontSize-XL) - 4px); /* Further reduce font size for smaller devices */
  }
}
