.tech-stack {
  text-align: center;
}

.title {
  margin-bottom: 4rem;
  font-size: var(--fontSize-XL);
}

.icon-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
  justify-items: center;
  margin-bottom: 5rem;
  max-width: 100%; 
}

.tech-icon {
  width: 50px;
  height: auto;
}

/* Smaller screens (e.g., tablets) */
@media (max-width: 768px) {
  .icon-container {
    grid-template-columns: repeat(3, 1fr); 
    margin: 0 auto; 
  }
}

/* Very small screens (e.g., mobile phones) */
@media (max-width: 480px) {
  .icon-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for very small screens */
    margin: 0 auto; 
  }
}
