@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*,
:root {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    /* Font */
    font-family: "Inter";
    --fontSize-small: 0.75rem;
    --fontSize-regular: 1rem;
    --fontSize-medium: 1.333rem;
    --fontSize-large: 1.777rem;
    --fontSize-XL: 2.369rem;
    --fontSize-2XL: 3.157rem;
    --fontSize-3XL: 4.209rem;
    --fontSize-4XL: 5.61rem;

    /* Variables */
    --backGroundColor: #051328;
    --backGroundColorTwo: #340956;
    --colorPrimary: #AE21D1;

    --defaultMargin: 5rem;
    --pageMargin: 23vw;
    --pageMargin-small: 15vw;
    --pageMargin-smaller: 8vw;
    --scrollPadding: 10rem;
    --borderRadiusSmall: 6px;
    --borderRadiusBig: var(--defaultMargin);
}

html {
    scroll-behavior: smooth;
    scroll-padding: var(--scrollPadding);
}

body {
    background: linear-gradient(90deg, var(--backGroundColor) 0%, var(--backGroundColorTwo) 100%);
    color: white;
}

h1 {
    font-size: var(--fontSize-4XL);
}

h2 {
    font-size: var(--fontSize-3XL);
}

h3 {
    font-size: var(--fontSize-2XL);
}

h4 {
    font-size: var(--fontSize-XL);
}

h5 {
    font-size: var(--fontSize-large);
}

h6 {
    font-size: var(--fontSize-medium);
}

p {
    font-size: var(--fontSize-regular);
}

.text-regular {
    font-weight: 400;
}

.text-light {
    font-weight: 200;
}

.page-cont {
    margin: 0 var(--pageMargin);
}

@media screen and (max-width: 992px) {

    *,
    :root {
        --pageMargin: 1em;
    }
}