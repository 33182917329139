.scroll-bar {
    position: fixed;
    display: flex;
    align-items: center;
    gap: 1em;
    transform: translateY(-50%);
    top: 50%;
    right: var(--defaultMargin);
    
}

.scroll-bar__track {
    display: block;
    background-color: rgba(255,255,255,0.3);
    position: relative;
    height: 40vh;
    width: 0.4em;
    min-height: 15rem;

}

.scroll-bar__elevator {
    background-color: white;
    position: absolute;
    width: 100%;
    transition: top 0.5s ease-in-out;

}

@media screen and (max-width: 992px) {
    .scroll-bar {
        display: none;
    }
}