.scroll-button-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(-50%);
  top: 80%;
  right: 4.7em;
  cursor: pointer;
  margin-top: 1em; 
}

.scroll-up-button {
  opacity: 1;
  font-size: var(--fontSize-regular);
  font-weight: bold;
  writing-mode: vertical-lr;
  text-orientation: sideways;
  transform: rotate(180deg);
  margin-bottom: 2em; 
}

.arrow {
  content: url('../../assets/vector-up.png');
  width: 0.75em;
  height: auto;
}

@media screen and (max-width: 992px) {
  .scroll-button-container {
      display: none;
  }
}
