
.text-block {
    text-align: center;
    align-items: center;
    white-space: nowrap;
    color: white;
}

.text-block h5 {
    margin-bottom: 1em;
    font-size: var(--fontSize-medium);
    font-weight: bold;
    opacity: 0.75;
}

.text-block h3{
    margin-bottom: 1.5em;
}


/*.text-block p {*/
/*    margin-bottom: 2em;*/
/*    font-size: var(--fontSize-medium);*/
/*    font-weight: bold;*/
/*    opacity: 0.75;*/
/*}*/

.send-button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    color: white;
    margin: 2em;
}

.phone-container {
    white-space: nowrap;
    display: flex;
    justify-content: center;
    margin-bottom: 4em;
}
