.hero {

    --offset: 5rem;

    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 5fr 1fr;
    grid-template-areas:
        "empty image"
        "text image"
        "empty2 image";

    height: auto;
    width: fit-content;
    margin: calc(var(--defaultMargin) * 1.5) auto;
    position: relative;
    right: var(--offset);
    color: white;

}

.hero__left {
    grid-area: text;
    position: relative;
    left: 5.6rem;
    justify-self: flex-end;
    width: fit-content;
    pointer-events: none;
    z-index: 2;

}

.hero__left>* {
    display: block;
    margin-right: 6rem;
}

.hero__left>h1 {
    margin-bottom: 1rem;
    line-height: 5rem;
}
.hero__left>h3 {
    margin-bottom: 1rem;
}
.hero__left>h5 {
    margin-top: 2rem;
}


.hero__right {
    grid-area: image;
    position: relative;

}

.hero__subheading {
    opacity: 0.75;
}

.button-container {
    margin: 2.5em 0;
    pointer-events: auto;
}

.hero__right {
    z-index: 1;
}

.hero__right img {

    transition: transform 0.2s;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

@media screen and (max-width: 992px) {

    .hero {        
        height: 100svh;
        grid-template-columns: 1em auto 1em;
        grid-template-rows: 50% 30%;
        grid-template-areas:
            "a    image b"
            "text text  text";

        /* height: auto; */
        /* width: fit-content; */
        margin: calc(var(--defaultMargin) * 1.5) auto;
        /* position: relative; */
        right: 0;
        /* color: white; */
        margin-top: 0;
        margin-bottom: -9svh;
    }

    .hero__left {
        grid-area: text;
        /* position: relative; */
        left: 0;
        bottom: var(--offset);
        justify-self: flex-start;
        /* width: fit-content; */
        /* pointer-events: none; */
        /* z-index: 2; */

    }

    .hero__right {
        grid-area: image;
        text-align: center;
    }

    .hero__right div,
    .hero__right>img {
        height: 100%;
    }

    .hero__right img {
        width: 80%;
    }

    .hero h1 {
        font-size: var(--fontSize-2XL);
        margin: 0;
        line-height: 4rem;
    }

    .hero h3 {
        font-size: var(--fontSize-XL);
        margin-top: 3rem;
        line-height: 1rem;
    }

    .hero h5 {
        font-size: var(--fontSize-medium);
        margin: 0;
    }
    .button-container{
        margin: 2em 0;
    }


}