.about-intro-section {
    margin-bottom: 6rem; /* Consolidated duplicate margin-bottom properties */
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2.5rem;
  }
  
  .about-intro-section p {
    font-size: var(--fontSize-regular);
    align-items: center;
  }
  
  /* Medium screens (e.g., tablets) */
  @media (max-width: 768px) {
    .about-intro-section {
      margin-left: var(--pageMargin-small); 
      margin-right: var(--pageMargin-small);
      line-height: 1.4rem; 
    }
  
    .about-intro-section p {
      font-size: calc(var(--fontSize-regular) - 2px);
    }
  }
  
  /* Small screens (e.g., mobile phones) */
  @media (max-width: 480px) {
    .about-intro-section {
      margin-left: var(--pageMargin-smaller); 
      margin-right: var(--pageMargin-smaller);
      line-height: 1.3rem; 
    }
  
    .about-intro-section p {
      font-size: calc(var(--fontSize-regular) - 4px); 
    }
  }
  