.item-card__cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--defaultMargin) * 1.5);
}

.item-card__header>h3 {
    margin-bottom: calc(var(--defaultMargin) / 2);
    cursor: pointer;
}

.item-card__header {
    max-width: 400px;
}

.item-card__media {
     height: 17rem;
     cursor: pointer;
}

@media screen and (max-width: 992px) {
    .item-card__cont {
        margin-right: calc(var(--defaultMargin) / 3);
        margin-left: calc(var(--defaultMargin) / 3);
    }
    .item-card__media{
        max-width: 80vw;
        height: auto;
        margin-bottom: calc(var(--defaultMargin) / 2);
    }
    .item-card__header>h3 {
        font-size: var(--fontSize-large);
        margin-bottom: 0;
    }
    .item-card__header {
        display: flex;
        align-items: flex-end;

    }

}