.button--secondary {
    padding: 0.1em 2em;
    position: relative;
    width: fit-content;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
}

.button--secondary:hover>.button--secondary__label {
    transform: scale(1.05);
}

.button--secondary:hover>.button--secondary__background {
    height: 100%;
}

.button--secondary__label,
.button--secondary>.button--secondary__background {
    transition: all 0.2s ease-out;
}

.button--secondary__background {
    background-color: var(--colorPrimary);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    z-index: -1;
}

.button--secondary__label {
    line-height: 1.6rem;
    font-weight: bold;
    font-size: var(--fontSize-medium);
}