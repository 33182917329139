.journey {
    margin: calc(var(--defaultMargin) * 2) 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
}

.journey__header {
    margin: 0 auto;
}

.journey__header>img {
    float: right;
    margin-right: 5px;
}

.journey__content {
    background-repeat: repeat-y;
    background-position-x: center;
}

@media screen and (min-width: 992px){
    .journey__content > div:nth-child(2n) {
        flex-direction: row-reverse;
    }

    .journey__content > div:nth-child(2n) > .item-card__header {
        display: flex;
        text-align: right;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media screen and (max-width: 992px) {
    .journey__content > div {
        flex-direction: column-reverse;
    }
}