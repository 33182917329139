.sidebar-logos {
    position: fixed;
    left: 3rem;
    top: 45vh;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}
.sidebar-logos--container {
    height: 33px;
    width: 33px;
    background-image: linear-gradient(to right, var(--colorPrimary) 50%, white 50%);
    transition: background-position 0.2s ease-out;
    background-size: 200% 100%;
    background-position: 100% 0;
    border-radius: 2px;
}
.sidebar-logos--container:hover {
    background-position: 0 0;
}

.sidebar-logos--container > img {
    height: 100%;
    transition: all 0.2s ease-out;
    cursor: pointer;
}
@media screen and (max-width: 992px) {
    .sidebar-logos{
        display: none;
    }
}
