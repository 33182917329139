.odometer {
    display: flex;
    flex-direction: row;
    height: 1em;
    overflow: hidden;
}

.odometer__column {
    line-height: 1em;
    position: relative;
    transition: top 0.5s ease-in-out;
}