.form-container {
    --input-padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container form {
    width: 50vh;
  }

  .input-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2em;
      margin-bottom: 15px;
  }
.form-input {
    position: relative;
    margin-bottom: 10px;
}
.form-input input:not(:placeholder-shown)+.form-input-label,
.form-input input:focus+.form-input-label,
.form-input textarea:not(:placeholder-shown)+.form-input-label,
.form-input textarea:focus+.form-input-label {
    /* Your desired styles for the label when the input is focused or has content */
    color: white;
    transform: translate(calc(var(--input-padding) * -1), -100%);
    top: 0;
}

.form-input-label {
    content: attr(data-label);
    position: absolute;
    top: 36%;
    transform: translateY(-50%);
    left: 2px;
    display: inline-block;
    color: gray;
    font-size: 0.85em;
    padding-left: var(--input-padding);
    text-align: left;
    z-index: 1000;
    pointer-events: none;
    transition: all 0.3s ease-out;
}

textarea~.form-input-label {
    transform: translateY(50%);
    top: 8px;
}

  .form-container input,
  textarea {
    width: 100%;
    padding: var(--input-padding);
    box-sizing: border-box;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 0.5em;
  }

  .form-container textarea {
    min-height: 15vh;
    min-width: 100%;
  }

.invisible-validation {
    visibility: hidden;
    font-size: var(--fontSize-small);
}

.input-error {
    visibility: visible;
    color: rgba(146, 6, 6, 0.95);
    font-size: var(--fontSize-small);
}

  
