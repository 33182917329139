.project-header {
    margin-top: calc(var(--defaultMargin) * 2);
}

.project-header > h6{
    margin-top: calc(var(--defaultMargin) /4);
    opacity: 60%;
}

.project-header--image-cont {
    margin-top: calc(var(--defaultMargin) /1.5);
    width: fit-content;
    height: fit-content;
    overflow: hidden;
}

.project-header--image-cont img, .project--image-cont img,video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.project-description{
    margin-top: calc(var(--defaultMargin) /1.5);
    opacity: 80%;
    text-align: justify;
}

.project-details{
    margin-top: calc(var(--defaultMargin) /2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
}

.project-details--header{
    font-weight: bold;
}
.project--image-cont {
    margin-top: calc(var(--defaultMargin) /1.5);
    width: 100%;
}

.project--image-cont img,video{
    margin-bottom: calc(var(--defaultMargin) /4);
}

.modal {
    position: fixed;
    z-index: 1000; 
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    max-width: 90%; 
    max-height: 80%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}

/* Adjust video and image styles within the modal as needed */
.modal-content img, .modal-content video {
    width: 100%;
    height: auto;
    border-radius: 4px;
}
